import React from 'react';
import { Route } from 'react-router-dom';

// Accepts a Layout component and a child component and renders both
// Rendering this way prevents the layout from having to re-render on route change
const LayoutRoute = ({ component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => <Layout {...props} component={component} />}
  />
);

export default LayoutRoute;
