// Changes to this file need to be mirrored in `server/common/rbac/actions.js`
export const ORGANIZATION_CREATE = 'ORGANIZATION_CREATE';
export const ORGANIZATION_EDIT = 'ORGANIZATION_EDIT';
export const ORGANIZATION_VIEW = 'ORGANIZATION_VIEW';
export const ADMIN_SETTINGS_EDIT = 'ADMIN_SETTINGS_EDIT';
export const ADMIN_SETTINGS_VIEW = 'ADMIN_SETTINGS_VIEW';
export const INSPECTION_ARCHIVE = 'INSPECTION_ARCHIVE';
export const INSPECTION_CREATE = 'INSPECTION_CREATE';
export const INSPECTION_EDIT = 'INSPECTION_EDIT';
export const INSPECTION_VIEW = 'INSPECTION_VIEW';
export const USER_CREATE = 'USER_CREATE';
export const DASHBOARD_VIEW = 'DASHBOARD_VIEW';
export const USER_VIEW = 'USER_VIEW';
export const USER_EDIT = 'USER_EDIT';
export const PULL_SHEET_VIEW = 'PULL_SHEET_VIEW';
export const SEND_COPY_CLIENT = 'SEND_COPY_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const EDIT_RESPONSE = 'EDIT_RESPONSE';
export const NOT_SUPER = 'NOT_SUPER';
export const TECH_VIEW = 'TECH_VIEW';
export const BILLING_VIEW = 'BILLING_VIEW';
export const CREATE_TECH = 'CREATE_TECH';

export default {
  ORGANIZATION_CREATE,
  ORGANIZATION_EDIT,
  ORGANIZATION_VIEW,
  ADMIN_SETTINGS_EDIT,
  ADMIN_SETTINGS_VIEW,
  INSPECTION_ARCHIVE,
  INSPECTION_CREATE,
  INSPECTION_EDIT,
  INSPECTION_VIEW,
  USER_CREATE,
  DASHBOARD_VIEW,
  USER_VIEW,
  USER_EDIT,
  PULL_SHEET_VIEW,
  SEND_COPY_CLIENT,
  ADD_CLIENT,
  EDIT_RESPONSE,
  NOT_SUPER,
  TECH_VIEW,
  BILLING_VIEW,
  CREATE_TECH,
};
