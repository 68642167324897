import React from 'react';
import { Button, Hidden, List, ListItem, makeStyles } from '@material-ui/core';

import AdapterLink from 'components/utils/AdapterLink';
import pages from './pages';
import styles from './styles';

import Can from 'components/helpers/Can';



const useStyles = makeStyles(styles);

const SidebarNav = ({ onClose }) => {
  const classes = useStyles();

  return (
    <List className={classes.nav}>
      {pages.map((page) => (
        <Hidden lgUp={page.mobileOnly} key={page.title}>
          <Can action={page.action} version={page.version}>
            <ListItem className={classes.item} disableGutters>
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={AdapterLink}
                to={page.href}
                onClick={onClose}
              >
                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </Button>
            </ListItem>
          </Can>
        </Hidden>
      ))}
    </List>
  );
};

export default SidebarNav;
