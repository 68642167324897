import React from 'react';
import {
    Typography,
    makeStyles,
} from '@material-ui/core';


import styles from './styles';

const useStyles = makeStyles(styles);

const TestCarousel = () => {
    const classes = useStyles();
    let pg1Class = ""
    let pg2Class = classes.dnone
    
    const switchPage = () => {
        if(pg1Class === classes.dnone){
            setTimeout(() => {
                pg1Class = ""
                pg2Class=classes.dnone
            }, 1000)
        }else if(pg2Class === classes.dnone){
            setTimeout(() => {
                pg1Class = classes.dnone
                pg2Class= ""
               

            }, 1000)
        }
    }
    switchPage()

    return (
        <div>
            <Typography className={pg1Class} variant="h1">Hello</Typography>
            <Typography className={pg2Class} variant="h1">Hi</Typography>
        </div>
    )
}

export default TestCarousel;