import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const AuthLayout = ({ component: Component }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <Grid className={classes.grid} container>
        <Grid className={classes.heroWrapper} item lg={5}>

        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <Component />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  AuthLayout.propTypes = {
    component: PropTypes.object,
  };
}

export default AuthLayout;
