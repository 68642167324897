import { colors } from '@material-ui/core';

export default (theme) => ({
  root: {
    flexBasis: '100%',
    margin: theme.spacing(2)
  },
  hintText: {
    textAlign: 'center'
  },
  buttonLabel: {
    marginRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right'
    }
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'start'
    }
  },
  buttonGroupNoLabel: {
    justifyContent: 'center'
  },
  buttonNegative: {
    backgroundColor: '#fafafa',
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: '#f0f0f0'
    }
  },
  buttonNegativeSelected: {
    backgroundColor: `${theme.palette.error.main} !important`,
    color: `${theme.palette.error.contrastText} !important`,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  buttonNeutral: {
    backgroundColor: '#fafafa',
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: '#f0f0f0'
    }
  },
  buttonNeutralSelected: {
    backgroundColor: `${theme.palette.primary.dark} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  buttonPositive: {
    backgroundColor: '#fafafa',
    color: colors.lightGreen[700],
    '&:hover': {
      backgroundColor: '#f0f0f0'
    }
  },
  buttonPositiveSelected: {
    backgroundColor: `${colors.lightGreen[700]} !important`,
    color: `${theme.palette.getContrastText(
      colors.lightGreen[700]
    )} !important`,
    '&:hover': {
      backgroundColor: colors.lightGreen[900]
    }
  }
});
