import { DISMISS_NOTIFICATION } from './constants';

export const dismissNotification = ({ id = '' } = {}) => {
  if (id) {
    return {
      type: DISMISS_NOTIFICATION,
      id
    };
  }
};
