import React from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Description = ({ description }) => {
  const classes = useStyles();

  if (!description) return null;

  return (
    <Grid container justifyContent="center">
      {description.map(({ images = [], label, notes = [] }, index) => (
        <Grid item xs={12} md={8} key={index}>
          {images.map((src, iIndex) => (
            <img
              key={iIndex}
              className={classes.listImage}
              src={src}
              alt={label}
            />
          ))}
          <Typography className={classes.listLabel}>{label}</Typography>
          <List>
            {notes.map((note, nIndex) => (
              <ListItem key={nIndex} className={classes.listItem}>
                <ListItemText>{note}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      ))}
    </Grid>
  );
};

export default Description;
