import React from 'react';
import Slide from '@material-ui/core/Slide';

// Helper component for common transition
const Transition = React.forwardRef((props, ref) => {
  return (
    <Slide
      direction="up"
      mountOnEnter={true}
      unmountOnExit={true}
      ref={ref}
      {...props}
    />
  );
});

export default Transition;
