import React from 'react';
import { NavLink } from 'react-router-dom';

// Allows for a ref forwarding. Necessary for component={NavLink}
const AdapterLink = React.forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <NavLink {...props} />
  </div>
));

export default AdapterLink;
