const handleFieldChange = (setState) => (event) => {
  const { checked, id, type, value } = event.target;
  const newValue = type === 'checkbox' ? checked : value;

  // const newValue = value;

  setState((prevState) => ({
    ...prevState,
    values: {
      ...prevState.values,
      [id]: newValue,
    },
  }));
};

export default handleFieldChange;
