import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VERSION_DASHARAMA } from 'components/app/InspectionForm/constants';

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

// Higher order component for public-facing components
// When accessed by an authenticated user, redirect to Dashboard
// When accessed by a non-authenticated user, display component
const publicComponent = (BaseComponent) => {

  return connect(mapStateToProps)(({ isAuthenticated, ...props }) => {
    const org = useSelector((state) => state.auth.organization);
    console.log(org);
    let dasharama = false;
    if (org && org.version) {
      dasharama = org.version === VERSION_DASHARAMA.value;
    }
    return isAuthenticated ? dasharama ? (
      <Redirect to='/dasharama' />
    ) : (
      <Redirect to="/dashboard" />
    ) : (
      <BaseComponent {...props} />
    );
  });
};

export default publicComponent;
