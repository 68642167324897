import jwt_decode from 'jwt-decode';

import setAuthToken from 'utils/setAuthToken';
import { setCurrentUser } from './actions';

// Final step of login
// Stores in localStorage if requested for persistence
// Assigns token to axios header
// Stores token in redux state
export const handleLogin = (res, remember = false, newUsr = false) => async (dispatch) => {
  const { token, org } = res.data;


  if (remember) {
    // Allow session to persist but storing token in localStorage
    const orgStr = JSON.stringify(org);

    localStorage.setItem('jwtToken', token);
    localStorage.setItem('org', orgStr);
  }

  // Use Auth Token in the header of HTTP requests
  setAuthToken(token);

  // Read JWT data into the state
  const decoded = jwt_decode(token);

  dispatch(setCurrentUser(decoded, org, newUsr));
};
