import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

import styles from './styles';

const useStyles = makeStyles(styles);

const Profile = ({ className }) => {
  const classes = useStyles();

  const user = useSelector((state) => state.auth.user);

  return (
    <div className={clsx(classes.root, className)}>
      <Typography className={classes.name} variant="h4">
        {user.name}
      </Typography>
      {user.role && user.role.label && (
        <Typography variant="body2">{user.role.label}</Typography>
      )}
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Profile.propTypes = {
    className: PropTypes.string,
  };
}

export default Profile;
