export const arePropsEqual = (prevProps, nextProps) => {
  return (
    prevProps.errors[prevProps.id] === nextProps.errors[nextProps.id] &&
    prevProps.touched[prevProps.id] === nextProps.touched[nextProps.id] &&
    prevProps.values[prevProps.id] === nextProps.values[nextProps.id]
  );
};

export const arePropsEqualObject = (prevProps, nextProps) => {
  return (
    prevProps.options === nextProps.options &&
    prevProps.errors[prevProps.id] === nextProps.errors[nextProps.id] &&
    prevProps.touched[prevProps.id] === nextProps.touched[nextProps.id] &&
    prevProps.values[prevProps.id].value ===
    nextProps.values[nextProps.id].value
  );
};

export const arePropsEqualArray = (prevProps, nextProps) => {
  if (!nextProps.values[nextProps.id] || !prevProps.values[prevProps.id]) {
    return
  }
  const valuesEqual =

    nextProps.values[nextProps.id].length ===
    prevProps.values[prevProps.id].length &&
    nextProps.values[nextProps.id].every((e) =>
      prevProps.values[prevProps.id].includes(e)
    );

  return (
    valuesEqual &&
    prevProps.errors[prevProps.id] === nextProps.errors[nextProps.id] &&
    prevProps.touched[prevProps.id] === nextProps.touched[nextProps.id]
  );
};

export default arePropsEqual;
