export const FLEDGE_ID = {
    value: '6137a74a398f7900191d32f5'
};

export const ARCTEC_ID = {
    value: '60fdaf97136a420019cfc38c'
};

export const CHIMNEY_CHAMPIONS = {
    value: '60f4d319cb6982001a941e19'
};

export const JACKSONVILLE_CHIMNEY_PROS = {
    value: '619aab177b99b700125e3c63'
}