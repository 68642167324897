import { makeStyles } from '@material-ui/styles';
import React from 'react';

import styles from './styles';
import Topbar from './components/Topbar';

const useStyles = makeStyles(styles);

const DasharamaLayout = ({ component: Component, ...rest }) => {
    const classes = useStyles();


    return (
        <div>
            <Topbar />
            <main className={classes.content}>
                <Component {...rest} />

            </main>
        </div>
    );
};

export default DasharamaLayout;