import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Can from 'components/helpers/Can';
import {
  AppBar,
  Badge,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Menu as MenuIcon,
  NotificationsOutlined,
  Settings,
} from '@material-ui/icons';

import Axios from 'axios';

import NotificationList from './NotificationList';
import Search from './Search';
import styles from './styles';
import { dismissNotification, readNotification } from 'actions/notifications';
import {
  ORGANIZATION_CREATE,
  ADMIN_SETTINGS_VIEW,
  ORGANIZATION_VIEW,
} from 'common/rbac/actions';

const useStyles = makeStyles(styles);

const MAX_NOTIFICATIONS = 3;

const Topbar = ({
  className,
  dismissNotification,
  onSidebarOpen,
  notifications,
  readNotification,
}) => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);

  const [companyName, setCompanyName] = React.useState();

  const [notificationsEl, setNotificationElement] = React.useState(null);
  const showNotifications = Boolean(notificationsEl);

  const unreadNotifications = Boolean(
    notifications.find((notification) => notification.read === false)
  );

  const markShownAsRead = () =>
    notifications
      .slice(0, MAX_NOTIFICATIONS)
      .map((notification) => readNotification(notification));

  const handleShowNotifications = (event) =>
    setNotificationElement(event.currentTarget);

  const handleClickNotification = (id) => dismissNotification({ id });
  const handleReloadApp = () => {
    window.location.reload(true);
  };

  const handleCloseNotifications = () => {
    setNotificationElement(null);
    markShownAsRead();
  };

  const [menuEl, setMenuElement] = React.useState(null);
  const showMenu = Boolean(menuEl);

  const handleShowMenu = (event) => setMenuElement(event.currentTarget);
  const handleCloseMenu = () => setMenuElement(null);

  React.useEffect(() => {
    Axios.post('/api/admin/org', { orgId: user.organizationId }).then(yourOrg => {
      setCompanyName(yourOrg.data.data.name)
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AppBar className={clsx(classes.root, className)}>
        <Toolbar>
          <RouterLink to="/">
            <img src='/static/logo_512.png' className={classes.logoImg} alt="Bear Hands Pro Logo" />
          </RouterLink>
          <Hidden xsDown>
            <Typography variant="h2" className={classes.logoText}>
              {companyName}
            </Typography>
          </Hidden>
          <div className={classes.flexGrow} />
          <Search />
          <IconButton
            className={classes.rightButton}
            color="inherit"
            onClick={handleShowNotifications}
          >
            <Badge
              badgeContent={unreadNotifications ? 1 : 0}
              color="secondary"
              variant="dot"
            >
              <NotificationsOutlined />
            </Badge>
          </IconButton>
          <Hidden mdDown>
            <IconButton
              className={classes.rightButton}
              color="inherit"
              onClick={handleShowMenu}
            >
              <Settings />
            </IconButton>
          </Hidden>
          <Hidden lgUp>
            <IconButton
              className={classes.rightButton}
              color="inherit"
              onClick={onSidebarOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Menu
        id="accountMenu"
        anchorEl={menuEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showMenu}
        onClose={handleCloseMenu}
      >
        <RouterLink to="/settings">
          <MenuItem onClick={handleCloseMenu}>User Settings</MenuItem>
        </RouterLink>
        <Can action={ORGANIZATION_VIEW}>
          <RouterLink to="/createUser">
            <MenuItem onClick={handleCloseMenu}>Create User</MenuItem>
          </RouterLink>
        </Can>
        <Can action={ORGANIZATION_CREATE}>
          <RouterLink to="/createorg">
            <MenuItem onClick={handleCloseMenu}>Create Organization</MenuItem>
          </RouterLink>
        </Can>
        <Can action={ADMIN_SETTINGS_VIEW}>
          <RouterLink to="/admin">
            <MenuItem onClick={handleCloseMenu}>Admin Control Panel</MenuItem>
          </RouterLink>
        </Can>
        <RouterLink to="/logout">
          <MenuItem onClick={handleCloseMenu}>Sign Out</MenuItem>
        </RouterLink>
      </Menu>
      <Popover
        anchorEl={notificationsEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handleCloseNotifications}
        open={showNotifications}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NotificationList
          notifications={notifications.slice(0, MAX_NOTIFICATIONS)}
          notificationsTotal={notifications.length}
          onClickNotification={handleClickNotification}
          handleReloadApp={handleReloadApp}
        />
      </Popover>
    </>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Topbar.propTypes = {
    className: PropTypes.string,
    dismissNotification: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    onSidebarOpen: PropTypes.func.isRequired,
    readNotification: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.app,
});

export default connect(mapStateToProps, {
  dismissNotification,
  readNotification,
})(Topbar);
