import axios from 'axios';

import { handleLogin, logoutUser } from './actions';

// Triggered to verify the validity of a token retrieved from localStorage
// Handles all thrown errors
// Creates request to validate token
// If valid, updates state to login
// If invalid, logs out user
export const verifyToken = () => async (dispatch) => {
  return axios
    .get('/api/auth/verify-token')
    .then((res) => {

      if (!res.data.success) {

        throw new Error();
      }
      // Current JWT is valid

      // Server issued new token to extend expiration
      if (res.data.token) {
        // Second argument is remember is always true if this is being called
        dispatch(handleLogin(res, true));
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 401) {
          // Server responded with a HTTP Authorization status therefore
          // JWT is invalid/expired
          dispatch(logoutUser());
        }
      }
    });
};
