import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#174159',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        color: '#fff',
        flexGrow: 1,
    },
}));

// eslint-disable-next-line
const Topbar = ({ }) => {
    const location = useLocation();
    const classes = useStyles();
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    // eslint-disable-next-line
    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    console.log(location.pathname)
    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <Typography variant="h3" className={classes.title}>
                    <RouterLink style={{ color: "#fff" }} to='/dasharama'>
                        Dasharama
                    </RouterLink>
                    {location.pathname === '/chimneyEd' || location.pathname === '/chimneyed' ? ': Chimney Educators' : location.pathname === '/doorsales' ? ': Security Advisors' : location.pathname === '/gas' ? ': Gas Educators' : location.pathname === '/chimneyRepair' ? ': Chimney Repair' : ''}
                </Typography>
                {auth && (
                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <RouterLink to="/chimneyEd">
                                <MenuItem onClick={handleClose} >Chimney Educators</MenuItem>
                            </RouterLink>
                            {/* <RouterLink to="/chimneyRepair">
                                <MenuItem onClick={handleClose} >Chimney Repair</MenuItem>
                            </RouterLink> */}
                            <RouterLink to="/gas">
                                <MenuItem onClick={handleClose} >Gas Educators</MenuItem>
                            </RouterLink>
                            <RouterLink to="/doorsales">
                                <MenuItem onClick={handleClose} >Security Advisors</MenuItem>
                            </RouterLink>

                        </Menu>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    )
};

export default Topbar;