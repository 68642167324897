import React from 'react';
import PropTypes from 'prop-types';
import { InputBase, makeStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { Search as SearchIcon } from '@material-ui/icons';
import { useParams, withRouter } from 'react-router';

import styles from './styles';

const useStyles = makeStyles(styles);

const Search = ({ location }) => {
  const classes = useStyles();

  const { filter } = useParams();
  const [input, setInput] = React.useState(filter || '');
  const [redirecting, setRedirecting] = React.useState(false);

  const handleChange = (event) => {
    setInput(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setRedirecting(true);
  };

  const redirectTo = `/search/${input}`;

  if (redirecting) {
    if (redirectTo === location.pathname) {
      // Post redirect handling
      setRedirecting(false);
    } else {
      // Redirect to search results
      return <Redirect to={redirectTo} />;
    }
  }

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.searchRoot,
            input: classes.searchInput
          }}
          inputProps={{ 'aria-label': 'search' }}
          value={input}
          onChange={handleChange}
        />
      </form>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Search.propTypes = {
    location: PropTypes.object
  };
}

export default withRouter(Search);
