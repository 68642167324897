import axios from 'axios';



// Triggered from form submission
// Throws errors
// Makes request with form data
export const resetPasswordRequest = (data) => async (dispatch) => {

  return axios
    .post('/api/auth/reset-password', data)
    .then((res) => {
      if (!res.data.success) {
        throw new Error();
      }

    })
    .catch((err) => {

      throw err.response.data.errors;
    });
};
