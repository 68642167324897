import { SET_USER, SET_LOADING, SET_NEW_USER } from '../actions/auth';
//should be the opposite
// isAuth: false
// isLoad: true
const initialState = {
  isAuthenticated: true,
  isLoading: false,
  user: {},
  organization: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: action.loading,
        user: {},
      };
    case SET_USER:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        isLoading: false,
        user: action.user,
        organization: action.org,
      };
    case SET_NEW_USER:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        isLoading: false,
        user: action.user,
        organization: action.org,
        newUser: true,
      };
    default:
      return state;
  }
};
