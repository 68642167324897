import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Divider, makeStyles, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Footer = ({ className }) => {
  const classes = useStyles();
  const [build] = React.useState(process.env.REACT_APP_BUILD);

  return (
    <div className={clsx(classes.root, className)}>
      <Divider />
      <Typography variant="body1">&copy; 2020</Typography>
      <Typography variant="body2">Build: {build}</Typography>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Footer.propTypes = {
    className: PropTypes.string,
  };
}

export default Footer;
