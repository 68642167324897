import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import styles from './styles';
import Topbar from './components/Topbar';

const useStyles = makeStyles(styles);

const AppLayout = ({ component: Component, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = React.useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const showSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={showSidebar}
        isDesktop={isDesktop}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>
        <Component {...rest} />
        <Footer className={classes.footer} />
      </main>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  AppLayout.propTypes = {
    component: PropTypes.any.isRequired,
  };
}

export default AppLayout;
