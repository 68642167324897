import React from 'react';
import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Close as CloseIcon, OpenInNew as OpenIcon } from '@material-ui/icons';

import Description from './Description';
import Transition from 'components/helpers/Transition';
import styles from './styles';

const useStyles = makeStyles(styles);

const Diagram = ({ description, justifyContent = 'flex-start', label, url }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [urlArray, setUrlArray] = React.useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };




  React.useEffect(() => {
    if (typeof (url) === 'string') {
      setUrlArray([url])
    } else {
      setUrlArray(url)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Grid item xs={12}>
      <Grid container justifyContent={justifyContent}>
        <Button onClick={handleOpen} endIcon={<OpenIcon />}>
          Show Diagram
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                className={classes.appBarCloseIcon}
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                className={classes.appBarTitle}
                color="inherit"
                variant="h4"
                noWrap
              >
                {label}
              </Typography>
            </Toolbar>
          </AppBar>

          {urlArray.map((urlImg, index) => {
            return <img src={urlImg} alt={label} key={index} className={classes.image} />

          })
          }
          <Description description={description} />
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                className={classes.appBarCloseIcon}
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                className={classes.appBarTitle}
                color="inherit"
                variant="h4"
                noWrap
              >
                {label}
              </Typography>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default Diagram;
