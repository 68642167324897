import axios from 'axios';

import { logoutUser } from './actions';

// Triggered from reset password
// Throws errors
// Makes request with form data
export const resetPassword = (passwords) => async (dispatch) => {

  return axios
    .post('/api/auth/password-reset', passwords)
    .then((res) => {
      if (!res.data.success) {
        throw new Error();
      }
      dispatch(logoutUser());
    })
    .catch((err) => {

      throw err.response.data.errors
    });
};
