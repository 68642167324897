import setAuthToken from 'utils/setAuthToken';
import { setCurrentUser } from './actions';

// Triggered when an invalid token is stored or on user request
// Removes any localStorage token
// Deauthenticates axios requests
// Updates redux state
export const logoutUser = () => async (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem('jwtToken');
  // Remove auth header from HTTP requests
  setAuthToken(false);
  // Set current user to empty object {}
  dispatch(setCurrentUser({}));
};
