import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';

import AppRouter from './AppRouter';
import store from './configureStore';
import theme from './theme';
import { checkSavedLogin } from './actions/auth';


import './styles/index.scss';

// Trigger a check for a saved JWT if it exists
store.dispatch(checkSavedLogin());


export default () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  </Provider>
);
