export default () => ({
  listLabel: {
    fontWeight: 'bold'
  },
  listImage: {
    maxWidth: '50%',
    objectFit: 'contain'
  },
  listItem: {}
});
