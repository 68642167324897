export default () => ({
  root: {},
  appBar: {
    position: 'relative'
  },
  appBarCloseIcon: {
    flex: 1,
    justifyContent: 'flex-start'
  },
  appBarTitle: {
    flexGrow: 1,
    display: 'block'
  },
  image: {
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'contain',
    height: '100%',
    maxWidth: '82%',
  }
});
