import { handleLogin, setUserLoading, verifyToken } from './actions';

// Checks for token locally
// Controls [state].auth.isLoading
// Triggers request to token validation request
export const checkSavedLogin = () => async (dispatch) => {
  const token = localStorage.getItem('jwtToken');
  const org = JSON.parse(localStorage.getItem('org'));


  if (!token || !org) {
    // State initializes with loading: true. Assign to false once we know
    dispatch(setUserLoading(false));
    return;
  }

  // Create session with stored token
  dispatch(handleLogin({ data: { token: token, org: org } }));

  // Validate token with server
  // If valid, server will issue a new token
  // If invalid, client will call logout
  if (window.navigator.onLine) {
    dispatch(verifyToken());
  }
};
