// Changes to this file need to be mirrored in `server/common/rbac/check.js`
import permissions from './permissions';

const check = (role, action, context) => {
  const rolePermissions = permissions[role];

  // Role is not defined
  if (!rolePermissions) {
    return false;
  }



  const actionPermission = rolePermissions[action];

  if (typeof actionPermission === 'boolean') {
    return actionPermission;
  }

  if (typeof actionPermission === 'function') {
    return actionPermission(context);
  }

  // Action is not defined within role
  return false;
};

export default check;
