import { READ_NOTIFICATION } from './constants';

export const readNotification = ({ id = '' } = {}) => {
  if (id) {
    return {
      type: READ_NOTIFICATION,
      id
    };
  }
};
