const handleFormError = (setState, unknownErrorProp) => (errors = false) => {
  console.log(errors);
  if (errors) {
    setState((prevState) => ({
      ...prevState,
      errors,
    }));
  } else {
    setState((prevState) => ({
      ...prevState,
      info: '',
      errors: {
        [unknownErrorProp]: 'An unknown error occurred',
      },
    }));
  }
};

export default handleFormError;
