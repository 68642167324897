import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Divider, Drawer, makeStyles } from '@material-ui/core';

import Profile from './Profile';
import SidebarNav from './SidebarNav';
import styles from './styles';

const useStyles = makeStyles(styles);

const Sidebar = ({ className, isDesktop, onClose, open, variant }) => {
  const classes = useStyles();

  const anchor = isDesktop ? 'left' : 'right';

  return (
    <Drawer
      anchor={anchor}
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav onClose={onClose} />
      </div>
    </Drawer>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Sidebar.propTypes = {
    className: PropTypes.string,
    isDesktop: PropTypes.bool,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
  };
}

export default Sidebar;
