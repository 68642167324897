import { combineReducers } from 'redux';
import auth from './authenticationReducer';
import notifications from './notificationsReducer';
import dasharama from './dasharamaReducer';

export default combineReducers({
  auth,
  notifications,
  dasharama,
});
