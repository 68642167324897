import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  CircularProgress,
  Link,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import publicComponent from 'components/helpers/publicComponent';
import styles from './styles';
import useFormState from 'utils/forms/useFormState';
import { attemptLogin } from 'actions/auth';

const useStyles = makeStyles(styles);

const LoginForm = ({ attemptLogin, isLoading }) => {
  const classes = useStyles();

  const {
    state: { values, errors },
    handleError,
    handleChange
  } = useFormState(
    {
      email: '',
      password: '',
      remember: true
    },
    'email'
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    attemptLogin(values).catch(handleError);
  };

  return (
    <form className={classes.root} noValidate onSubmit={handleSubmit}>
      <Typography className={classes.title} variant="h2">
        Sign in
      </Typography>
      <div className={classes.fields}>
        <TextField
          id="email"
          label="Email address"
          autoComplete="email"
          autoFocus
          required
          type="email"
          className={classes.textField}
          variant="outlined"
          onChange={handleChange}
          value={values.email}
        />
        <Typography className={classes.fieldError} variant="body2">
          {errors.email}
        </Typography>
        <TextField
          id="password"
          label="Password"
          autoComplete="current-password"
          required
          type="password"
          className={classes.textField}
          variant="outlined"
          onChange={handleChange}
          value={values.password}
        />
        <Typography className={classes.fieldError} variant="body2">
          {errors.password}
        </Typography>
        <div className={classes.remember}>
          <Checkbox
            checked={values.remember}
            className={classes.rememberCheckbox}
            color="primary"
            id="remember"
            onChange={handleChange}
          />
          <Typography className={classes.rememberText} variant="body1">
            Keep Me Signed In
          </Typography>
        </div>
      </div>
      <div className={classes.signInWrapper}>
        <Button
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit}
          color="primary"
          className={classes.signInButton}
          size="large"
          variant="contained"
        >
          Sign In
        </Button>
        {isLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
      <Typography className={classes.subText} variant="body1">
        Don't have an account?{' '}
        <Link
          className={classes.subTextUrl}
          to="/signup"
          component={RouterLink}
        >
          Sign Up
        </Link>
      </Typography>
      <Typography className={classes.subText} variant="body1">
        Forgot your password?{' '}
        <Link
          className={classes.subTextUrl}
          to="/password-reset"
          component={RouterLink}
        >
          Reset Password
        </Link>
      </Typography>
    </form>
  );
};

if (process.env.NODE_ENV !== 'production') {
  LoginForm.propTypes = {
    attemptLogin: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
  };
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading
});

export default compose(
  connect(
    mapStateToProps,
    { attemptLogin }
  ),
  publicComponent
)(LoginForm);
