import React from 'react';
import clsx from 'clsx';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
    Button,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    makeStyles,
    TextField,
    Typography
} from '@material-ui/core';

import styles from './styles';
import useFormState from 'utils/forms/useFormState';

import { resetPassword } from 'actions/auth';


const useStyles = makeStyles(styles);

const Reset = ({ className, email, resetPassword }) => {
    const classes = useStyles();
    const { token } = useParams();

    const [success, setSuccess] = React.useState(false);
    const [redirectNow, setRedirectNow] = React.useState(false);

    const {
        state: { values, errors, info },
        handleChange,
        handleError,
        handleSuccess
    } = useFormState(
        {
            newPassword: '',
            repeatNewPassword: '',
            token: token,
        },
        'newPassword'
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        resetPassword(values)
            .then(() => {
                handleSuccess('Successfully updated');
                setSuccess(true);
            })
            .catch((err) => {
                handleError(err);
            }
            );
    };




    React.useEffect(() => {
        if (success) {

            setTimeout(() => {

                setRedirectNow(true);
            }, 1000)

        };



    }, [success, redirectNow])


    if (redirectNow) {
        return <Redirect to={'/login'} />
    }

    return (
        <Card className={clsx(classes.root, className)}>
            <CardHeader subheader="Update password" title="Password" />
            <Divider />
            <form className={classes.form} onSubmit={handleSubmit}>
                <CardContent>
                    <input
                        type="text"
                        name="email"
                        autoComplete="username email"
                        value={email}
                        readOnly
                        style={{ display: 'none' }}
                    />
                    <TextField
                        className={classes.textField}
                        label="New Password"
                        id="newPassword"
                        onChange={handleChange}
                        autoComplete="new-password"
                        type="password"
                        value={values.newPassword}
                        variant="outlined"
                    />
                    <div className={classes.fieldHelper}>
                        <Typography className={classes.fieldError} variant="body2">
                            {errors.newPassword}
                        </Typography>
                    </div>
                    <TextField
                        className={classes.textField}
                        label="Confirm New password"
                        id="repeatNewPassword"
                        onChange={handleChange}
                        autoComplete="new-password"
                        type="password"
                        value={values.repeatNewPassword}
                        variant="outlined"
                    />
                    <div className={classes.fieldHelper}>
                        <Typography className={classes.fieldError} variant="body2">
                            {errors.repeatNewPassword}
                        </Typography>
                        <Typography className={classes.fieldInfo} variant="body2">
                            {info}
                        </Typography>
                    </div>
                </CardContent>
                <Divider />
                <CardActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        type="submit"
                        onClick={handleSubmit}
                    >
                        Update Password
          </Button>
                </CardActions>
            </form>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.auth.isLoading
});





export default compose(connect(mapStateToProps, { resetPassword }))(Reset);
