import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  CircularProgress,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import publicComponent from 'components/helpers/publicComponent';
import styles from './styles';
import useFormState from 'utils/forms/useFormState';
import { registerUser } from 'actions/auth';

const useStyles = makeStyles(styles);

const Register = ({ isLoading, registerUser }) => {
  const classes = useStyles();

  const {
    state: { values, errors },
    handleError,
    handleChange,
  } = useFormState(
    {
      name: '',
      email: '',
      password: '',
      repeatPassword: '',
      policy: false,
    },
    'name'
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    registerUser(values).catch(handleError);
  };

  return (
    <form className={classes.root} noValidate onSubmit={handleSubmit}>
      <Typography className={classes.title} variant="h2">
        Create new account
      </Typography>

      <div className={classes.fields}>
        <TextField
          id="name"
          label="Name"
          autoComplete="name"
          autoFocus
          required
          type="text"
          className={classes.textField}
          variant="outlined"
          onChange={handleChange}
          value={values.name}
        />
        <Typography className={classes.fieldError} variant="body2">
          {errors.name}
        </Typography>
        <TextField
          id="email"
          label="Email Address"
          autoComplete="email"
          required
          type="text"
          className={classes.textField}
          variant="outlined"
          onChange={handleChange}
          value={values.email}
        />
        <Typography className={classes.fieldError} variant="body2">
          {errors.email}
        </Typography>
        <TextField
          id="password"
          label="Password"
          autoComplete="off"
          required
          type="password"
          className={classes.textField}
          variant="outlined"
          onChange={handleChange}
          value={values.password}
        />
        <Typography className={classes.fieldError} variant="body2">
          {errors.password}
        </Typography>
        <TextField
          id="repeatPassword"
          label="Repeat Password"
          autoComplete="off"
          required
          type="password"
          className={classes.textField}
          variant="outlined"
          onChange={handleChange}
          value={values.repeatPassword}
        />
        <Typography className={classes.fieldError} variant="body2">
          {errors.repeatPassword}
        </Typography>
        <div className={classes.policy}>
          <Checkbox
            checked={values.policy}
            className={classes.policyCheckbox}
            color="primary"
            id="policy"
            onChange={handleChange}
          />
          <Typography className={classes.policyText} variant="body1">
            I have read and agree to the{' '}
            <Link className={classes.policyUrl} to="#">
              Terms and Conditions
            </Link>
            .
          </Typography>
        </div>
        <Typography className={classes.fieldError} variant="body2">
          {errors.policy}
        </Typography>
      </div>
      <div className={classes.submitWrapper}>
        <Button
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit}
          color="primary"
          className={classes.submitButton}
          size="large"
          variant="contained"
        >
          Sign Up
        </Button>
        {isLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
      <Typography className={classes.signIn} variant="body1">
        Have an account?{' '}
        <Link className={classes.signInUrl} to="/login" component={RouterLink}>
          Sign In
        </Link>
      </Typography>
    </form>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Register.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    registerUser: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
});

export default compose(
  connect(mapStateToProps, { registerUser }),
  publicComponent
)(Register);
