export default (theme) => ({
    root: {
        cover: {
            width: 151,
        },
    },
    // root: {
    //     display: 'flex',

    // },
    center: {
        display: 'flex',
        justifyContent: 'center',
    },
    textCenter: {
        textAlign: 'center',
    },
    cover: {
        height: 200,

    },
    mt2: {
        marginTop: 10,
    },
    mt2Highest: {
        marginTop: 10,
        backgroundColor: '#C5EFCB',
    },
    blue: {
        border: '1px solid #174159',

    },
    blueHr: {
        borderColor: '#2B5F9F',
    },
    purple: {
        border: '1px solid #643F90',
    },
    purpleHr: {
        borderColor: '#643F90',
    },
    mb2: {
        marginBottom: 10,
    },
    m3: {
        margin: 15,
    },
    bigP: {
        fontSize: 20,
    },
    boxShadow: {
        shadowColor: '#000',
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,

    },
    dnone: {
        display: 'none',
    },
    my2: {
        marginTop: 10,
        marginBottom: 10,
    },
    backDrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    fab: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
    squareCard: {
        position: 'relative',
        marginBottom: '5px',
        height: '29vh',
    },
    overlay: {
        zIndex: 2,
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0px',
        left: '0px',
        backgroundColor: '#ffffff55',
        '&:hover': {
            backgroundColor: "#ffffff70",
         },
    },
    cardTitles: {
        textAlign: 'center',
        padding: '10px'
    },

    overlayText: {
        color:'#000',
        position: 'absolute',
        width: '100%',
        left: '0px',
        textAlign: 'center',
        top: '35%',
        zIndex: '3',
        fontWeight: 'bold',
        fontSize: '40px',

    },


});