import React from 'react';
import { Dialog, DialogContent, LinearProgress } from '@material-ui/core';

import Login from '../Login';

const LoginDialog = () => (
  <>
    <LinearProgress />
    <Dialog open={true}>
      <DialogContent>
        <Login />
      </DialogContent>
    </Dialog>
  </>
);

export default LoginDialog;
