import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { VERSION_LITE, ARCTEC_ID, VERSION_CHECKARTEC, VERSION_PRO } from 'components/app/InspectionForm/constants';
import roles from 'common/rbac/roles';

import check from 'common/rbac/check';

// Component wrapper to determine whether the authenticated user has access to perform the restricted task
// Example:
// <Can
//   action={USER_EDIT}
//   context={...}
// >
//   <UserEditTable />
// </Can>

const Can = ({ action, children, context = {}, no = false, version = VERSION_LITE.value }) => {
  const { user, organization } = useSelector((state) => state.auth);

  if (!user || !organization) {
    return <Redirect to={'/login'} />;
  }

  if (!user) {
    return <Redirect to={'/login'} />;
  }


  if (!user.role) {
    return <Redirect to={'/login'} />;
  }

  if (organization.subscriptionStatus === 'canceled' && user.role.value === roles.ADMINISTRATOR.value) {
    return <Redirect to={'/billing'} />
  }

  if (organization.subscriptionStatus === 'canceled' && user.role.value !== roles.ADMINISTRATOR.value) {
    return <Redirect to={'/accountnotactive'} />
  }

  const role = user.role.value;

  const hasPermission = check(role, action, { ...context, user });

  if (version === VERSION_CHECKARTEC.value) {
    if ((hasPermission && organization._id === ARCTEC_ID.value) || (hasPermission && organization.version === VERSION_PRO.value)) {

      return <>{children}</>;
    }
  }

  if ((hasPermission && version === VERSION_LITE.value)
    || (hasPermission && user.version === version)
    || (hasPermission && version === VERSION_LITE.value && organization._id === ARCTEC_ID.value)) {
    return <>{children}</>;
  }

  if (!no) {
    return <></>;
  }

  return no;
};

export default Can;
