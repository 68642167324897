import { VERSION_LITE } from "../generic";

export const UTIL_CHIM_APPLIANCE_STATE = {
    utilChimApplianceNumber: '',
    utilChimAppliancePhoto: [],
    utilChimApplianceDataPlatePhotos: [],
    utilChimApplianceType: '',
    utilChimApplianceFuelType: '',
    utilChimApplianceVersion: VERSION_LITE.value,
    utilChimApplianceState: '',
    utilChimApplianceLiteIssues: [],
    utilChimApplianceLitePhotos: [],
    utilChimApplianceTakeOffSize: '',
    utilChimConnectorPipePhotos: [],
    utilChimConnectorPipeType: '',
    utilChimConnectorPipeRise: '',
    utilChimConnectorPipeState: '',
    utilChimConnectorPipeVersion: VERSION_LITE.value,
    utilChimConnectorPipeLiteIssues: [],
    utilChimConnectorPipeLitePhotos: [],
    utilChimAppThimblePhotos: [],
    utilChimAppThimbleAccess: '',
    utilChimAppThimbleState: '',
    utilChimAppThimbleVersion: VERSION_LITE.value,
    utilChimAppThimbleLiteIssues: [],
    utilChimAppThimbleLitePhotos: [],
    utilChimAppThimbleDistance: '',
    utilChimConnectorPipe45d: '',
    utilChimConnectorPipe90d: '',
    utilChimApplianceBPH: '',
    utilChimApplianceBTU: ''
};