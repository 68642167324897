export default (theme) => ({
    root: {
        width: '100%',
        margin: theme.spacing(2)
    },
    form: {},
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    fieldHelper: {
        marginBottom: theme.spacing(1)
    },
    fieldError: {
        color: theme.palette.error.main
    },
    fieldInfo: {
        color: theme.palette.success.main
    },
    divider: {
        marginBottom: theme.spacing(2),
        backgroundColor: "black"
    }
});
