export const CATALYTIC_6R2 = {
  label: '6" Round x 2"',
  value: 'CATALYTIC_6R2'
};
export const CATALYTIC_7R2 = {
  label: '7" Round x 2"',
  value: 'CATALYTIC_7R2'
};
export const CATALYTIC_25_13_2 = {
  label: '2 1/2" x 13" x 2"',
  value: 'CATALYTIC_25_13_2'
};
