import axios from 'axios';

import { handleLogin, setUserLoading } from './actions';

// Controls updates to [state].auth.isLoading
// Triggers request for login to server
// Throws errors
export const attemptLogin = ({ remember = false, ...userData } = {}) => async (
  dispatch
) => {
  dispatch(setUserLoading(true));
  return axios
    .post('/api/auth/login', userData)
    .then((res) => {
      if (!res.data.success) {
        // Invalid user credentials
        throw new Error();
      }

      // Process returned token
      return dispatch(handleLogin(res, remember));
    })
    .catch((err) => {
      dispatch(setUserLoading(false));
      throw err.response.data.errors;
    });
};
