import axios from 'axios';

//import { handleLogin, setUserLoading } from './actions';

// Triggered by form submission
// Controls [state].auth.isLoading
// Makes request to the server to create new account
// Logs in as user if successful
// Throws errors
export const registerUser = (data) => async (dispatch) => {
  return axios
    .post('/api/auth/register', data)
    .then((res) => {
      if (!res.data.success) {
        throw new Error();
      }
      return res;
      // Reducer will disable loading after logged in
      // dispatch(handleLogin(res, true));
    })
    .catch((err) => {
      // dispatch(setUserLoading(false));
      throw err.response.data.errors;
    });
};
