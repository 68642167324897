import { SET_USER, SET_NEW_USER } from './constants';

// Action generator triggered by requests
// Should only be accessed by other auth action generators
export const setCurrentUser = (user, org, newUsr) => (
  !!newUsr ? {
    type: SET_NEW_USER,
    isAuthenticated: Object.keys(user).length !== 0,
    org,
    user,
  } : {
    type: SET_USER,
    isAuthenticated: Object.keys(user).length !== 0,
    org,
    user,
  });
