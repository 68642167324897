export default (theme) => ({
  root: {
    boxShadow: 'none'
  },
  logoText: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap'
  },
  flexGrow: {
    flexGrow: 1
  },
  rightButton: {
    marginLeft: theme.spacing(1)
  },
  logoImg: {
    maxWidth: "50px",
  }
});
