import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';


import AppLayout from 'layouts/AppLayout';
import DasharamaLayout from 'layouts/DasharamaLayout';
import AuthLayout from 'layouts/AuthLayout';

import LayoutRoute from 'components/utils/LayoutRoute';

import Login from 'components/auth/Login';
import Logout from 'components/auth/Logout';
import PasswordReset from 'components/auth/PasswordReset';
import Register from 'components/auth/Register';


import CreateUser from 'components/app/components/CreateUser';



import Reset from 'components/auth/reset';

import Dasharama from 'components/app/Dasharama';
import ChimneyEd from 'components/app/Dasharama/pages/chimneyed';
import DoorSales from 'components/app/Dasharama/pages/doorsales';
import TestCarousel from 'components/app/Dasharama/TestCarousel';
import GasSales from 'components/app/Dasharama/pages/gasSales';



const forwardToDashboard = () => <Redirect to="/dashboard" push={false} />;

export default () => {

  return (
    <BrowserRouter>
      <Switch>
        <LayoutRoute
          path="/"
          exact={true}
          component={Login}
          layout={AuthLayout}
        />

        <Route path="/logout" component={Logout} />
        {/* Public Routes */}
        <LayoutRoute path="/login" component={Login} layout={AuthLayout} />
        <LayoutRoute
          path="/password-reset"
          component={PasswordReset}
          layout={AuthLayout}
        />
        <LayoutRoute path='/reset/:token?' component={Reset} layout={AuthLayout} />
        <LayoutRoute path="/register" component={Register} layout={AuthLayout} />
        {/* Private Routes */}

        <LayoutRoute
          path="/createUser"
          component={CreateUser}
          layout={AppLayout}
        />

        {/* dasharama routes */}
        <LayoutRoute path='/dasharama' component={Dasharama} layout={DasharamaLayout} />
        <LayoutRoute path='/chimneyEd' component={ChimneyEd} layout={DasharamaLayout} />
        <LayoutRoute path='/doorsales' component={DoorSales} layout={DasharamaLayout} />
        <LayoutRoute path='/gas' component={GasSales} layout={DasharamaLayout} />

        <LayoutRoute path='/testcaro' component={TestCarousel} layout={DasharamaLayout} />

        {/* 404 */}
        <Route component={forwardToDashboard} />
      </Switch>
    </BrowserRouter>
  )
};
